/*MARGINS*/
.no-margins {margin: 0;}
.no-margin-top {margin-top:0;}
.no-margin-bottom {margin-bottom:0;}

.margin-top-0 {margin-top: 0;}
.margin-top-5 {margin-top: 5px;}
.margin-top-10 {margin-top: 10px;}
.margin-top-15 {margin-top: 15px;}
.margin-top-30 {margin-top: 30px;}

.margin-bottom-0 {margin-bottom: 0;}
.margin-bottom-5 {margin-bottom: 5px;}
.margin-bottom-10 {margin-bottom: 10px;}
.margin-bottom-15 {margin-bottom: 15px;}
.margin-bottom-30 {margin-bottom: 30px;}

/*PADDINGS*/
.no-padding {padding: 0;}

.padding-right-0 {padding-right: 0;}
.padding-right-5 {padding-right: 5px;}
.padding-right-10 {padding-right: 10px;}
.padding-right-15 {padding-right: 15px;}

.padding-top-0 {padding-top: 0;}
.padding-top-5 {padding-top: 5px;}
.padding-top-10 {padding-top: 10px;}
.padding-top-15 {padding-top: 15px;}

.padding-bottom-0 {padding-bottom: 0;}
.padding-bottom-5 {padding-bottom: 5px;}
.padding-bottom-10 {padding-bottom: 10px;}
.padding-bottom-15 {padding-bottom: 15px;}

.padding-left-0 {padding-left: 0;}
.padding-left-5 {padding-left: 5px;}
.padding-left-10 {padding-left: 10px;}
.padding-left-15 {padding-left: 15px;}

/*FONT-SIZES*/
.font-size-15 {font-size: 15px;}
.font-size-20 {font-size: 20px;}
.font-size-25 {font-size: 25px;}
.font-size-30 {font-size: 30px;}

.selectize-input {
	border: 2px solid #bdc3c7;
	color: #34495e;
	padding: 9px 12px;
	height: 42px;
}

/*SCAFOLD*/
.fill {
	width: 100%;
	height: 100%;
}

.bg-grey {
	background-color: grey;
}

/*COLORS*/
.text-light-grey {
    color: #aaa;
}


.text-nav {
  font-size: 12px;
  font-weight: bold;
  color: #959595;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/*FLEX CLASSES*/
.flex {
    display: flex;
    width: 100%;
    height: 100%;
}

.flex-centered {
    justify-content: center;
}

.flex-aligned {
    align-items: center;
}

.flex-stacked {
    flex-direction: column;
}

.flex-space {
    justify-content: space-between;
}

.flex-space-around {
    justify-content: space-around;
}

.flex-guts {
    justify-content: center;
    align-items: center;
}

.flex-top {
    align-items: flex-start;
}

.flex-bottom {
    align-items: flex-end;
}

.flex-end {
    justify-content: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

/*Child element styles*/
.flex-full {
    flex: 1;
}